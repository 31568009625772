<template>
  <div class="card">
    <h2 class="card-title">Mis Notificaciones</h2>
    <DataTable
        :show-filter="true"
        :header="headers"
        :body="body"
        :endpoint="NotificacionesServices.api.findByUser"
        @rowClicked="verNotificacion"
    />
  </div>
</template>

<script>
import DataTable from "@/components/elements/DataTable.vue";
import {NotificacionesServices} from "@/modules/notificaciones/services/NotificacionesServices";

export default {
  name: "MisNotificaciones",
  components: {DataTable},
  computed: {
    NotificacionesServices() {
      return NotificacionesServices
    }
  },
  data() {
    return {
      headers: NotificacionesServices.misNotificacionesHeaders(),
      body: NotificacionesServices.misNotificacionesBody()
    };
  },
  methods: {
    verNotificacion(notificacion) {
      this.$router.push({ name: "VerMiNotificacion", params: { id: notificacion.uuid } });
    },
  },
}
</script>

<style scoped>

</style>
